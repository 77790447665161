import {
  TableHeaders,
  CellContentType
} from '@/shared/components/table/models';

export const PodHeaders: TableHeaders[] = [
  { key: 'number', label: 'NO', tdClass: 'min-w-50' },
  {
    key: 'orderNumber',
    label: `WO #`,
    sortable: true
  },
  {
    key: 'containerNumber',
    label: `CONT #`,
    sortable: true
  },
  {
    key: 'driverId',
    label: `Driver`,
    sortable: true
  },
  {
    key: 'fromLocationId',
    label: `Delivery Form`,
    sortable: true
  },
  {
    key: 'toLocationId',
    label: `Delivery To`,
    sortable: true
  },
  {
    key: 'deliveryDate',
    label: `DILV DATE`,
    sortable: true,
    disabled: true,
    content: {
      type: CellContentType.Date
    }
  },
  {
    key: 'notice',
    label: `NOTICE`,
    sortable: true,
    content: {
      type: CellContentType.Button,
      label: 'Text'
    }
  }
];
