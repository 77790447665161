import {
  TableHeaders,
  CellContentType
} from '@/shared/components/table/models';

export const BillHeaders: TableHeaders[] = [
  { key: 'number', label: 'NO', tdClass: 'min-w-50' },
  {
    key: 'orderNumber',
    label: `WO #`,
    sortable: true
  },
  {
    key: 'containerNumber',
    label: `CONT #`,
    sortable: true
  },
  {
    key: 'driverId',
    label: `Driver`,
    sortable: true
  },
  {
    key: 'originLocation',
    label: `Origin`,
    sortable: true
  },
  {
    key: 'destinationLocation',
    label: `Destination`,
    sortable: true
  },
  {
    key: 'returnLocation',
    label: `Return`,
    sortable: true
  },
  {
    key: 'checked',
    label: ``,
    sortable: false,
    content: {
      type: CellContentType.Checkbox
    }
  },
  {
    key: 'accountCode',
    label: `ACCT Code`,
    sortable: true
  },
  {
    key: 'accountCodeName',
    label: `ACCT Code Name`,
    sortable: true
  },
  {
    key: 'amount',
    label: `Amount`,
    sortable: true
  },
  {
    key: 'description',
    label: `Desc`,
    sortable: true
  },
  {
    key: 'pod',
    label: `POD`,
    sortable: true,
    content: {
      type: CellContentType.Icon,
      label: 'Click to open POD file',
      icon: 'file-earmark',
      isDisabledBy: 'podUploaded'
    }
  },
  {
    key: 'reject',
    label: `Reject`,
    sortable: true,
    content: {
      type: CellContentType.Button,
      label: 'Reject'
    }
  },
  {
    key: 'approval',
    label: `Approval`,
    sortable: true,
    content: {
      type: CellContentType.Button,
      label: 'Approval'
    }
  }
];
