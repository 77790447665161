export enum PropertyType {
  TrailNumber = 'trailNumber',
  OrderNumber = 'orderNumber',
  ContainerNumber = 'containerNumber',
  ReferenceNumber = 'referenceNumber',
  ChassisNumber = 'chassisNumber',
  PONumber = 'poNumber',
  MasterBillOfLading = 'masterBillOfLading',
  BookingNumber = 'bookingNumber',
  BillTo = 'billTo',
  PulloutLocation = 'pulloutLocation',
  PickupLocation = 'pickupLocation',
  DeliveryLocation = 'deliveryLocation',
  ReturnLocation = 'returnLocation',
  Driver = 'driver'
}

export enum MainOrderType {
  ImportRegular = 'IMP-REG',
  ExportRegular = 'EXP-REG',
  VanRegular = 'VAN',
  CyToCy = 'CY-TO-CY',
  BareChassis = 'BARE-CHASSIS',

  // TODO update after API reports wil be done
  IMP = 'IMP',
  EXP = 'EXP',
  CTY = 'CTY',
  CHS = 'CHS'
}

export const allPropertyTypes = [
  { value: PropertyType.ContainerNumber, text: 'CONT#' },
  { value: PropertyType.OrderNumber, text: 'WO#' },
  { value: PropertyType.ReferenceNumber, text: 'REF#' },
  { value: PropertyType.PONumber, text: 'PO#' },
  { value: PropertyType.MasterBillOfLading, text: 'MBL#' },
  { value: PropertyType.BillTo, text: 'Bill To' },
  { value: PropertyType.PickupLocation, text: 'P/U' },
  { value: PropertyType.DeliveryLocation, text: 'Delivery' },
  { value: PropertyType.ReturnLocation, text: 'Return' },
  { value: PropertyType.Driver, text: 'DRV ID' }
];

export const exportPropertyTypes = [
  { value: PropertyType.ContainerNumber, text: 'CONT#' },
  { value: PropertyType.OrderNumber, text: 'WO#' },
  { value: PropertyType.ReferenceNumber, text: 'REF#' },
  { value: PropertyType.PONumber, text: 'PO#' },
  { value: PropertyType.BookingNumber, text: 'BK#' },
  { value: PropertyType.BillTo, text: 'Bill To' },
  { value: PropertyType.PickupLocation, text: 'P/U' },
  { value: PropertyType.DeliveryLocation, text: 'Delivery' },
  { value: PropertyType.ReturnLocation, text: 'Return' },
  { value: PropertyType.Driver, text: 'DRV ID' }
];

export const vanPropertyTypes = [
  { value: PropertyType.TrailNumber, text: 'Trailer#' },
  { value: PropertyType.OrderNumber, text: 'WO#' },
  { value: PropertyType.ReferenceNumber, text: 'REF#' },
  { value: PropertyType.PONumber, text: 'PO#' },
  { value: PropertyType.MasterBillOfLading, text: 'BK#' },
  { value: PropertyType.BillTo, text: 'Bill To' },
  { value: PropertyType.PickupLocation, text: 'P/U' },
  { value: PropertyType.DeliveryLocation, text: 'Delivery' },
  { value: PropertyType.ReturnLocation, text: 'Return' },
  { value: PropertyType.Driver, text: 'DRV ID' }
];

export const barePropertyTypes = [
  { value: PropertyType.ChassisNumber, text: 'CHASSIS#' },
  { value: PropertyType.OrderNumber, text: 'WO#' },
  { value: PropertyType.ReferenceNumber, text: 'REF#' },
  { value: PropertyType.BillTo, text: 'Bill To' },
  { value: PropertyType.PickupLocation, text: 'P/U' },
  { value: PropertyType.DeliveryLocation, text: 'Delivery' },
  { value: PropertyType.ReturnLocation, text: 'Return' }
];

export const mainTypeModels = {
  [MainOrderType.ImportRegular]: allPropertyTypes,
  [MainOrderType.ExportRegular]: exportPropertyTypes,
  [MainOrderType.VanRegular]: vanPropertyTypes,
  [MainOrderType.CyToCy]: allPropertyTypes,
  [MainOrderType.BareChassis]: barePropertyTypes
};
