
















































































import { Component, Vue, Watch } from 'vue-property-decorator';
import TmsTable from '@/shared/components/table/TmsTable.vue';
import { TableHeaders } from '@/shared/components/table/models';

import { BillHeaders } from './models/BillHeaders';
import BillFilters from './BillFilters/BillFilters.vue';

import { DriverModule } from '@/store/index';
import { DriverTypes } from '../models/driver.model';
import { DriverService } from '@/shared/services/driver/driver.service';
import Loading from '@/shared/components/Loading.vue';
import { ToastHelper } from '@/utils/toast.util';
import WoBaseModal from '@/pages/Main/components/WoBaseModal.vue';
import { WorkOrder } from '@/shared/models/wo.model';
import { generateRouterClasses } from '@/shared/services/generate-router-classes';
import { JobsService } from '@/shared/services/jobs/jobs.service';
import { PermissionsService } from '@/shared/services/permission/permission.service';

@Component({
  components: { TmsTable, BillFilters, Loading, WoBaseModal }
})
export default class Bill extends Vue {
  driverModule = DriverModule;
  loading = false;
  type = '';
  currentRecord: any;

  selectedWo: WorkOrder = null;
  classes: string[] = [];

  columns: TableHeaders[] = BillHeaders;
  approvalList = [];
  perPage = 50;
  disabled = false;

  @Watch('$route', { immediate: true })
  onRouterChange() {
    DriverModule.init();
    this.type = this.$route.params.type.toUpperCase();
    this.classes = generateRouterClasses(this.$route);
    this.search();
  }

  constructor() {
    super();
  }

  get tableData() {
    return this.driverModule.driverData;
  }

  get isLastPage(): boolean {
    return !this.driverModule.meta.lastKey;
  }

  created() {
    this.disabled = PermissionsService.can('MOBILE ADMIN', '', 'View');
  }

  async search() {
    this.loading = true;
    try {
      await this.driverModule.search({
        driverType: DriverTypes[this.type]
      });
      this.approvalList = [];
    } catch (err) {
      ToastHelper.show(
        `${this.$route.params.type}/${this.$route.name.replace(
          `${this.$route.params.type}-`,
          ''
        )}`,
        err.message,
        5000,
        'danger'
      );
    } finally {
      this.loading = false;
    }
  }

  onInputChange(rowData) {
    if (rowData.data.checked) {
      this.approvalList.push(rowData.data.id);
    } else {
      this.approvalList = this.approvalList.filter(
        item => item != rowData.data.id
      );
    }
    console.log(this.approvalList);
  }

  action(rowData) {
    if (rowData.key === 'row') {
      this.openWorkOrderDetail(rowData.data);
    } else if (rowData.key === 'pod') {
      this.downloadPodFile(rowData?.data);
    } else {
      this.currentRecord = rowData;
      this.$bvModal.show(`${rowData.key}-modal`);
    }
  }

  hide(id) {
    this.$bvModal.hide(id);
  }

  async approve() {
    this.loading = true;
    if (this.approvalList && this.approvalList.length > 1) {
      this.multiApprove();
    } else {
      await this.singleApprove();
    }
  }

  private async singleApprove() {
    try {
      await DriverService.approve(
        DriverTypes[this.type],
        this.currentRecord?.data?.id
      );
      this.search();
    } catch (err) {
      ToastHelper.show(
        `${this.$route.params.type}/${this.$route.name.replace(
          `${this.$route.params.type}-`,
          ''
        )}`,
        err.message,
        5000,
        'danger'
      );
    } finally {
      this.loading = false;
      this.hide(`approval-modal`);
    }
  }

  //Temp: code to send multiple approval requests by using single approval api.
  private multiApprove() {
    let index = 0;
    this.approvalList.forEach(async id => {
      try {
        await DriverService.approve(DriverTypes[this.type], id);
        index++;
        if (this.approvalList.length === index) {
          this.search();
        }
      } catch (err) {
        ToastHelper.show(
          `${this.$route.params.type}/${this.$route.name.replace(
            `${this.$route.params.type}-`,
            ''
          )}`,
          err.message,
          5000,
          'danger'
        );
      } finally {
        this.loading = false;
        this.hide(`approval-modal`);
      }
    });
  }

  async reject() {
    console.log(this.currentRecord);
    this.loading = true;
    try {
      await DriverService.reject(
        DriverTypes[this.type],
        this.currentRecord?.data?.id
      );
      this.search();
    } catch (err) {
      ToastHelper.show(
        `${this.$route.params.type}/${this.$route.name.replace(
          `${this.$route.params.type}-`,
          ''
        )}`,
        err.message,
        5000,
        'danger'
      );
    } finally {
      this.loading = false;
      this.hide(`${this.currentRecord.key}-modal`);
    }
  }

  openWorkOrderDetail(data: WorkOrder) {
    this.selectedWo = data;
    this.$bvModal.show('wo-base-modal');
  }

  async downloadPodFile(data: any) {
    this.loading = true;
    try {
      const result = await JobsService.getPodFile(data.jobId, data.driverId);
      if (result && result.data) {
        window.open(result.data);
      }
    } catch (e) {
      console.log(e);
      ToastHelper.show(
        `${this.$route.params.type}/${this.$route.name.replace(
          `${this.$route.params.type}-`,
          ''
        )}`,
        'POD file not found!',
        5000,
        'danger'
      );
    } finally {
      this.loading = false;
    }
  }
}
