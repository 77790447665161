import {
  TableCategory,
  TableHeaders,
  CellContentType
} from '@/shared/components/table/models';
import { DATE_API_FORMAT } from '@/utils/date.util';
import moment from 'moment';

export const ManifestHeaders: TableHeaders[] = [
  { key: 'number', label: 'NO', tdClass: 'min-w-50' },
  {
    key: 'invoiceDate',
    label: `INV DATE`,
    sortable: true,
    disabled: true,
    formatter: getDay
  },
  {
    key: 'statusLabel',
    label: `Status`,
    sortable: true
  },
  {
    key: 'driverId',
    label: `Driver`,
    sortable: true
  },
  {
    key: 'orderNumber',
    label: `WO #`,
    sortable: true
  },
  {
    key: 'containerNumber',
    label: `CONT #`,
    sortable: true
  },
  {
    key: 'originLocation',
    label: `Origin`,
    sortable: true
  },
  {
    key: 'destinationLocation',
    label: `Destination`,
    sortable: true
  },
  {
    key: 'returnLocation',
    label: `Return`,
    sortable: true
  },
  {
    key: 'accountCode',
    label: `ACCT Code`,
    sortable: true
  },
  {
    key: 'accountCodeName',
    label: `ACCT Code Name`,
    sortable: true
  },
  {
    key: 'description',
    label: `Desc`,
    sortable: true
  },
  {
    key: 'amount',
    label: `Rate`,
    sortable: true
  },
  {
    key: 'pod',
    label: `POD`,
    sortable: true,
    content: {
      type: CellContentType.Icon,
      label: 'Click to open POD file',
      icon: 'file-earmark',
      isDisabledBy: 'podUploaded'
    }
  }
];

function getDay(schedule) {
  return schedule
    ? moment(schedule, DATE_API_FORMAT).format('MM/DD/YY')
    : schedule;
}
