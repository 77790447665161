

























import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Fragment } from 'vue-fragment';

import { FilterInput } from '@/shared/services/filter/filter.service';
import {
  convertAPIToDateFormat,
  convertDateFieldToAPIFormatWithoutTime
} from '@/utils/date.util';

import moment from 'moment';

@Component({
  components: { Fragment }
})
export default class DateFilter extends Vue {
  @Prop() toFilter: FilterInput<string>;
  @Prop() fromFilter: FilterInput<string>;
  @Prop() filterField: string;

  to = null;
  from = null;

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange() {
    this.to = null;
    this.from = null;

    this.toFilter.search = null;
    this.fromFilter.search = null;
  }

  convertDateFieldToAPIFormatWithoutTime = convertDateFieldToAPIFormatWithoutTime;

  maxDate = null;

  // dateTypes = [
  //   { value: DateType.CreatedDate, text: 'Created Date' },
  //   { value: DateType.PulloutDate, text: 'P/OUT' },
  //   { value: DateType.DeliveryDate, text: 'Delivery' },
  //   { value: DateType.MTYPU, text: 'P/U' },
  //   { value: DateType.ReturnDate, text: 'Return' }
  // ];

  created() {
    this.to = convertAPIToDateFormat(this.toFilter.search);
    this.from = convertAPIToDateFormat(this.fromFilter.search);
    // this.filterField === 'date'
    //   ? (this.maxDate = moment().format())
    //   : (this.maxDate = null);
  }

  fieldChange() {
    // this.filterField === 'date'
    //   ? (this.maxDate = moment().format())
    //   : (this.maxDate = null);
    // this.toFilter.field = this.filterField;
    // this.fromFilter.field = this.filterField;
  }
}
